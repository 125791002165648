import { useEffect } from 'react';
import Layout from '../components/Layout';
import QuestionBox from '../components/QuestionBox';
import HeaderInfo from '../components/HeaderInfo';

function FAQPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const items = [
        {
            id: 'q1',
            label: 'Q: I don’t have insurance, so I haven’t been to a dentist in a few years. Nothing hurts so do I really need a checkup?',
            content: [
                'A: Having regular checkups prevents major problems. Unfortunately, many dental problems occur without any warning. Nothing hurts until there is a significant problem. Before you know it, you’ve got problems. Extensive dental care is often required for patients who delay, postpone and avoid regularly scheduled visits. Prevention is the key to avoiding costly, extensive dental care. Undetected decay, cysts, tumors, and dental abscesses often lie “silently” below the surface – causing no pain or swelling. Why wait for these problems to surface through the onset of a painful experience?',
            ],
        },
        {
            id: 'q2',
            label: 'Q: I have been told I have bad breath. What over the counter product can I buy to prevent this?',
            content: [
                'A: 80% of bad breath (a condition called halitosis) comes from tooth decay, gum disease and bacteria on the tongue. Only about 2% comes from certain stomach conditions. Your best bet is to talk to your dentist about this. A complete examination of the teeth and gums is necessary in order to determine the source.',
            ],
        },
        {
            id: 'q3',
            label: 'Q: My teeth don’t hurt, but my dentist says I have problems with them. Is that possible?',
            content: [
                'A: As with medical problems, many times you are unaware until there is a significant problem. Undetected dental problems such as decay, abscesses, wear from a misaligned bite, tumors, oral cancer, cysts, and periodontal disease often lie dormant with no signs of pain or swelling. Your dentist can often diagnose these problems before they turn into nightmares! If you are unsure about the diagnosis you’ve been given, tell your dentist. In most cases your dentist will be more than willing to help you to actually “see” the problems through the use of dental x-rays, intra-oral cameras and digital cameras.',
            ],
        },
        {
            id: 'q4',
            label: 'Q: My gums don’t bleed but my dentist says I have gum disease? Is that possible?',
            content: [
                'A: Periodontal disease (gum disease) is often referred to as a “silent disease” that can lead to bone loss and eventual tooth loss. Although bleeding gums can be an early indication of periodontal disease, bleeding is not always present. A periodontal charting evaluation can be performed to measure the depth of detached tissue around the root structure of your teeth. This measurement will indicate the severity of the gum disease and help your dentist to monitor improvement as periodontal treatment is performed. If you are a smoker, the odds are significant that you will have gum disease and your gums may never bleed. Ask your dentist for an evaluation.',
            ],
        },
        {
            id: 'q5',
            label: 'Q: How often should I have my teeth cleaned?',
            content: [
                'A: The simple answer is that you should have your teeth cleaned as often as your hygienist and your dentist recommends. The more detailed answer is that it depends on the health of your gums and how committed you are to your oral hygiene program.',
                'For example, if your gums are healthy and you follow a sound oral hygiene program, you need to have to have your teeth cleaned every six months. I strongly recommend that everyone come to the dentist twice a year to be checked for oral cancer and signs of other diseases whose symptoms first appear in the mouth.',
                'On the other hand, if someone has moderate to advanced gum disease or isn’t willing to actively participate in a oral hygiene program, it may be necessary—if he wants any hope of keeping his teeth—to have them cleaned every 3 to 4 months.',
                'The actual frequency will depend on the severity of your gum infection and how effective you are at doing your part.',
            ],
        },
        {
            id: 'q6',
            label: 'Q: What languages are spoken at Millcroft Dental Care?',
            content: ['A: English, Italian, Romanian, and Polish.'],
        },
    ];

    return (
        <Layout
            title='Millcroft Dental Care | Frequently Asked Questions'
            content="Many people have common questions when getting an appoitment at Millcroft Dental Care. We've taken a lot of these common questions and answered them here in your frequently asked questions section, where you can take a look to see if you also have a question that falls into one of these frequently asked questions."
            path='faq'
        >
            <div className='faq'>
                <div className='faq__header'>
                    <HeaderInfo
                        title='Frequently Asked Questions'
                        link='Frequently Asked Questions'
                    />
                </div>
                <QuestionBox items={items} />
            </div>
        </Layout>
    );
}

export default FAQPage;
