export const formatDate = (dateString) => {
    const date = new Date(dateString);

    const options = {
        month: 'short',
        day: 'numeric',
    };
    const formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate;
};
