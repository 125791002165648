import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaGoogle } from 'react-icons/fa';

import FooterBackground from '../assets/footer.jpg';

function Footer() {
    return (
        <footer className='footer'>
            <div className='footer__background'>
                <img
                    className='footer__background__image'
                    src={FooterBackground}
                    alt='footer background'
                />
                <div className='footer__background__content'>
                    <div className='row'>
                        <div className='col-1-of-4'>
                            <h3 className='footer__title'>Our Location</h3>
                            <p className='footer__paragraph footer__paragraph--bold'>
                                Millcroft Dental Care
                            </p>
                            <p className='footer__paragraph'>
                                2020 Appleby Line, Unit B4A
                            </p>
                            <p className='footer__paragraph'>
                                Burlington, ON L7L 6M6
                            </p>
                            <p className='footer__paragraph'>
                                Phone: (905) 315-9888
                            </p>
                            <p className='footer__paragraph'>
                                Email: info@millcroftdentalcare.com
                            </p>
                        </div>
                        <div className='col-1-of-4'>
                            <h3 className='footer__title'>Our Hours</h3>
                            <p className='footer__paragraph'>
                                Monday 8:00 AM - 8:00 PM
                            </p>
                            <p className='footer__paragraph'>
                                Tuesday 8:00 AM - 8:00 PM
                            </p>
                            <p className='footer__paragraph'>
                                Wednesday 8:00 AM - 8:00 PM
                            </p>
                            <p className='footer__paragraph'>
                                Thursday 8:00 AM - 8:00 PM
                            </p>
                            <p className='footer__paragraph'>
                                Friday 8:00 AM - 3:00 PM
                            </p>
                            <p className='footer__paragraph'>
                                Saturday 9:00 AM - 3:00 PM
                            </p>
                            <p className='footer__paragraph'>Sunday Closed</p>
                        </div>
                        <div className='col-1-of-4'>
                            <h3 className='footer__title'>Explore</h3>
                            <ul className='footer__list'>
                                <li className='footer__list__item'>
                                    <Link
                                        className='footer__list__item__link'
                                        to='/our-office'
                                    >
                                        Our Office
                                    </Link>
                                </li>
                                <li className='footer__list__item'>
                                    <Link
                                        className='footer__list__item__link'
                                        to='/our-services'
                                    >
                                        Our Services
                                    </Link>
                                </li>
                                {/* <li className='footer__list__item'>
                                    <Link
                                        className='footer__list__item__link'
                                        to='/our-blog'
                                    >
                                        Our Blog
                                    </Link>
                                </li> */}
                                <li className='footer__list__item'>
                                    <Link
                                        className='footer__list__item__link'
                                        to='/patient-form'
                                    >
                                        Patient Form
                                    </Link>
                                </li>
                                <li className='footer__list__item'>
                                    <Link
                                        className='footer__list__item__link'
                                        to='/contact-us'
                                    >
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className='col-1-of-4'>
                            <h3 className='footer__title'>Stay Connected</h3>
                            <div className='u-margin-top-small'>
                                <a
                                    className='footer__link'
                                    href='https://www.facebook.com/pages/Millcroft-Dental/371430102948691'
                                >
                                    <FaFacebookF />
                                </a>
                                <a className='footer__link' href='#!'>
                                    <FaInstagram />
                                </a>
                                <a
                                    className='footer__link'
                                    href='https://www.google.com/maps/place/Millcroft+Dental+Care/@43.3936091,-79.7965518,17z/data=!3m1!4b1!4m5!3m4!1s0x882b6122ee77dfb3:0xa86f6389144d9b2d!8m2!3d43.3936052!4d-79.7943631'
                                >
                                    <FaGoogle />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
