import { renderBio } from '../utils/renderBio';

import MauroFattore from '../assets/doctors/MauroFattore.jpg';
import HelenSheng from '../assets/doctors/HelenSheng.jpg';
import IbrahimMohammad from '../assets/doctors/IbrahimMohammad.jpg';

function HomeDoctorsSection({ setBioState, openModal }) {
    return (
        <section className='home__doctors'>
            <h2 className='heading__secondary'>Meet Our Doctors</h2>
            <div className='row'>
                <div className='col-1-of-2'>
                    <div className='home__doctors__display'>
                        <img
                            className='home__doctors__display__image'
                            src={MauroFattore}
                            alt=''
                        />
                    </div>
                    <p className='home__doctors__title'>Dr. Mauro Fattore</p>
                    <button
                        onClick={() => renderBio(1, setBioState, openModal)}
                        className='home__doctors__button'
                    >
                        Read Bio
                    </button>
                </div>
                <div className='col-1-of-2'>
                    <div className='home__doctors__display'>
                        <img
                            className='home__doctors__display__image'
                            src={HelenSheng}
                            alt=''
                        />
                    </div>
                    <p className='home__doctors__title'>Dr. Helen Sheng</p>
                    <button
                        onClick={() => renderBio(2, setBioState, openModal)}
                        className='home__doctors__button'
                    >
                        Read Bio
                    </button>
                </div>
            </div>
            <div className='row'>
                <div className='col-1-of-2'>
                    <div className='home__doctors__display'>
                        <img
                            className='home__doctors__display__image'
                            src={IbrahimMohammad}
                            alt=''
                        />
                    </div>
                    <p className='home__doctors__title'>Dr. Ibrahim Mohammad</p>
                    <button
                        onClick={() => renderBio(3, setBioState, openModal)}
                        className='home__doctors__button'
                    >
                        Read Bio
                    </button>
                </div>
                <div className='col-1-of-2'>&nbsp;</div>
            </div>
        </section>
    );
}

export default HomeDoctorsSection;
