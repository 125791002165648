import { Link } from 'react-router-dom';

function HomePatientFormSection() {
    return (
        <section className='home__patientform'>
            <div className='row'>
                <div className='col-2-of-3'>
                    <p className='home__patientform__text'>
                        Already booked your appointment?
                    </p>
                </div>
                <div className='col-1-of-3'>
                    <Link
                        className='home__patientform__button'
                        to='/patient-form'
                    >
                        Patient Form
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default HomePatientFormSection;
