import { Helmet } from 'react-helmet';
import Navigation from './Navigation';
import Footer from './Footer';
import GoogleReview from './GoogleReview';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Layout({ title, content, path, children }) {
    return (
        <div style={{ overflow: 'hidden' }}>
            <Helmet>
                <title>{title}</title>
                <meta name='description' content={content} />
                <link
                    rel='canonical'
                    href={`https://millcroftdentalcare.com/${path}`}
                />
            </Helmet>
            <ToastContainer />
            <Navigation />
            <GoogleReview />
            {children}
            <Footer />
        </div>
    );
}

export default Layout;
