import { Link } from 'react-router-dom';
import { formatDate } from '../utils/formatDate';

function BlogPost({ title, excerpt, thumbnail, thumbnail_alt, date, link }) {
    return (
        <div className='blogpost'>
            <div className='blogpost__main'>
                <img
                    className='blogpost__main__image'
                    src={thumbnail}
                    alt={thumbnail_alt}
                />
            </div>
            <div className='row'>
                <div className='col-1-of-3'>
                    <p className='blogpost__date'>{formatDate(date)}</p>
                </div>
                <div className='col-2-of-3'>
                    <div className='blogpost__content'>
                        <h3 className='blogpost__content__title'>{title}</h3>
                        <p className='blogpost__content__description'>
                            {excerpt}
                        </p>
                        <div className='blogpost__view'>
                            <Link
                                className='blogpost__view__link'
                                to={`/our-blog/${link}`}
                            >
                                Read More
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogPost;
