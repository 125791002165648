import { Oval } from 'react-loader-spinner';

function HomeContactForm({
    name,
    email,
    message,
    loading,
    onChange,
    onSubmit,
}) {
    return (
        <form className='home__contact__form' onSubmit={onSubmit}>
            <input
                className='home__contact__form__input'
                name='name'
                type='text'
                placeholder='Name'
                onChange={onChange}
                value={name}
                required
            />
            <input
                className='home__contact__form__input'
                name='email'
                type='email'
                placeholder='Email'
                onChange={onChange}
                value={email}
                required
            />
            <textarea
                className='home__contact__form__textarea'
                name='message'
                cols='30'
                rows='10'
                placeholder='Message'
                onChange={onChange}
                value={message}
                required
            ></textarea>
            <div className='home__contact__form__submit'>
                {loading ? (
                    <div className='home__contact__form__submit__loader'>
                        <Oval color='#424242' height={50} width={50} />
                    </div>
                ) : (
                    <button
                        className='home__contact__form__submit__button'
                        htmltype='submit'
                    >
                        Send
                    </button>
                )}
            </div>
        </form>
    );
}

export default HomeContactForm;
