import { useState } from 'react';

function useBioModal() {
    const [modalOpen, setModalOpen] = useState(false);
    const [bioState, setBioState] = useState(null);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return {
        modalOpen,
        openModal,
        closeModal,
        bioState,
        setBioState,
    };
}

export default useBioModal;
