import { Link } from 'react-router-dom';

function ContactBox() {
    return (
        <div className='contactbox'>
            <div className='row'>
                <div className='col-2-of-3'>
                    <h3 className='contactbox__heading'>Questions?</h3>
                    <p className='contactbox__paragraph'>
                        Contact us for more information or to schedule an
                        appointment for a dental service.
                    </p>
                </div>
                <div className='col-1-of-3'>
                    <div className='contactbox__contact'>
                        <Link className='contactbox__link' to='/contact-us'>
                            Contact Us Today
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactBox;
