import { useState, useEffect } from 'react';
import useImageModal from '../hooks/use-image-modal';
import Layout from '../components/Layout';
import HeaderInfo from '../components/HeaderInfo';
import Modal from '../components/Modal';
import OfficeImages from '../components/OfficeImages';

function OfficePage() {
    const { modalOpen, openModal, closeModal } = useImageModal();

    const [image, setImage] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            title='Millcroft Dental Care | Our Office'
            content='Millcroft Dental Care is a comfortable environment with professional equipment and staff. High care is taken into making patients feel like they are receiving a high quality service.'
            path='our-office'
        >
            <div className='office'>
                {modalOpen && (
                    <Modal office show={modalOpen} onClose={closeModal}>
                        {image}
                    </Modal>
                )}
                <div className='office__header'>
                    <HeaderInfo title='Our Office' link='Our Office' />
                </div>
                <OfficeImages setImage={setImage} openModal={openModal} />
            </div>
        </Layout>
    );
}

export default OfficePage;
