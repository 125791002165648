import GoogleReviewButton from '../assets/google_review_button.png';

function GoogleReview() {
    return (
        <div className='googlereview'>
            <a href='https://www.google.com/search?q=millcroft+dental+care&oq=millcroft+dental+care&aqs=chrome..69i57j69i61l3.2533j0j1&sourceid=chrome&ie=UTF-8#lrd=0x882b6122ee77dfb3:0xa86f6389144d9b2d,1,,,'>
                <img
                    className='googlereview__image'
                    src={GoogleReviewButton}
                    alt='google review button'
                />
            </a>
        </div>
    );
}

export default GoogleReview;
