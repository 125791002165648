import { renderImage } from '../utils/renderImage';

import OfficeImage1 from '../assets/office/OfficeImage01.jpg';
import OfficeImage2 from '../assets/office/OfficeImage02.jpg';
import OfficeImage3 from '../assets/office/OfficeImage03.jpg';
import OfficeImage4 from '../assets/office/OfficeImage04.jpg';
import OfficeImage5 from '../assets/office/OfficeImage05.jpg';
import OfficeImage6 from '../assets/office/OfficeImage06.jpg';

function OfficeImages({ setImage, openModal }) {
    return (
        <>
            <div className='row office__marginTop'>
                <div className='col-1-of-3'>
                    <div className='office__display'>
                        <img
                            onClick={() => renderImage(0, setImage, openModal)}
                            className='office__display__image'
                            src={OfficeImage1}
                            alt=''
                        />
                    </div>
                </div>
                <div className='col-1-of-3'>
                    <div className='office__display'>
                        <img
                            onClick={() => renderImage(1, setImage, openModal)}
                            className='office__display__image'
                            src={OfficeImage2}
                            alt=''
                        />
                    </div>
                </div>
                <div className='col-1-of-3'>
                    <div className='office__display'>
                        <img
                            onClick={() => renderImage(2, setImage, openModal)}
                            className='office__display__image'
                            src={OfficeImage3}
                            alt=''
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-1-of-3'>
                    <div className='office__display__portrait'>
                        <img
                            onClick={() => renderImage(3, setImage, openModal)}
                            className='office__display__portrait__image'
                            src={OfficeImage4}
                            alt=''
                        />
                    </div>
                </div>
                <div className='col-1-of-3'>
                    <div className='office__display__portrait'>
                        <img
                            onClick={() => renderImage(4, setImage, openModal)}
                            className='office__display__portrait__image'
                            src={OfficeImage5}
                            alt=''
                        />
                    </div>
                </div>
                <div className='col-1-of-3'>
                    <div className='office__display__portrait'>
                        <img
                            onClick={() => renderImage(5, setImage, openModal)}
                            className='office__display__portrait__image'
                            src={OfficeImage6}
                            alt=''
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default OfficeImages;
