import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../components/Layout';
import HeaderInfo from '../components/HeaderInfo';
import BlogPost from '../components/BlogPost';

function BlogPage() {
    const [blogPosts, setBlogPosts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const config = {
                headers: {
                    Accept: 'application/json',
                },
            };

            try {
                const res = await axios.get('/api/blog/', config);

                if (res.status === 200) {
                    setBlogPosts(res.data);
                }
            } catch (err) {}
        };

        fetchData();
    }, []);

    const renderBlogPosts = () => {
        const display = [];
        const result = [];

        blogPosts.map((blog) => {
            display.push(
                <BlogPost
                    title={blog.title}
                    thumbnail={blog.thumbnail}
                    thumbnail_alt={blog.thumbnail_alt}
                    excerpt={blog.excerpt}
                    date={blog.date_updated}
                    link={blog.slug}
                />
            );
        });

        for (let i = 0; i < blogPosts.length; i += 2) {
            result.push(
                <div key={blogPosts[i].slug} className='row'>
                    <div className='col-1-of-2'>{display[i]}</div>
                    <div className='col-1-of-2'>
                        {display[i + 1] !== null &&
                        display[i + 1] !== undefined ? (
                            display[i + 1]
                        ) : (
                            <div>&nbsp;</div>
                        )}
                    </div>
                </div>
            );
        }

        return result;
    };

    return (
        <Layout
            title='Millcroft Dental Care | Blog'
            content='Check out our blog posts where you can get all kinds of insights when it comes to your dental needs.'
            path='our-blog'
        >
            <div className='blog__header'>
                <HeaderInfo title='Our Blog' link='Our Blog' />
            </div>

            <div className='blog__main'>
                {blogPosts.length > 0 ? (
                    <div className='row'>{renderBlogPosts()}</div>
                ) : (
                    <p className='blog__main__info'>
                        No blog posts currently posted
                    </p>
                )}
            </div>
        </Layout>
    );
}

export default BlogPage;
