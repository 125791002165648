import { useState } from 'react';
import Navbar from './Navbar';
import SideDrawer from './SideDrawer';

function Navigation() {
    const [showSideDrawer, setShowSideDrawer] = useState(false);

    const handleSideDrawerClose = () => {
        setShowSideDrawer(false);
    };

    const handleSideDrawerToggle = () => {
        setShowSideDrawer(!showSideDrawer);
    };

    return (
        <>
            <Navbar onToggle={handleSideDrawerToggle} />
            <SideDrawer open={showSideDrawer} onClose={handleSideDrawerClose} />
        </>
    );
}

export default Navigation;
