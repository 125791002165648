import { useState } from 'react';

function useImageModal() {
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return {
        modalOpen,
        openModal,
        closeModal,
    };
}

export default useImageModal;
