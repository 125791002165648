import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

function useContactForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [loading, setLoading] = useState(false);

    const { name, email, message } = formData;

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);

        const config = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        const body = JSON.stringify({
            name,
            email,
            message,
        });

        setLoading(true);

        try {
            const res = await axios.post('/api/contact/', body, config);

            if (res.status === 200) {
                setFormData({
                    ...formData,
                    name: '',
                    email: '',
                    message: '',
                });
                toast.success('Message Sent.');
            } else {
                toast.error('Message Send Failed.');
            }
        } catch (err) {
            toast.error('Message Send Failed.');
        }

        setLoading(false);
    };

    return {
        name,
        email,
        message,
        loading,
        onChange,
        onSubmit,
    };
}

export default useContactForm;
