import { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';

function useGoogleMap() {
    const [googleMap, setGoogleMap] = useState(null);

    useEffect(() => {
        const renderMarkers = (map, maps) => {
            new maps.Marker({
                position: { lat: 43.393701, lng: -79.79422 },
                map,
                title: 'Millcroft Dental Care',
            });
        };

        setGoogleMap(
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: 'AIzaSyA-Np_QabLbo8X6iJ2cDQyZubEyaCqZPa8',
                }}
                defaultCenter={{ lat: 43.393701, lng: -79.79422 }}
                defaultZoom={13.5}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                style={{ width: '100%', height: '350px' }}
            />
        );
    }, []);

    return {
        googleMap,
    };
}

export default useGoogleMap;
