import { useState } from 'react';

function QuestionBox({ items }) {
    const [expandedIndex, setExpandedIndex] = useState(-1);

    const handleClick = (nextIndex) => {
        setExpandedIndex((currentExpandedIndex) => {
            if (currentExpandedIndex === nextIndex) {
                return -1;
            } else {
                return nextIndex;
            }
        });
    };

    const renderedItems = items.map((item, index) => {
        const isExpanded = index === expandedIndex;

        const icon = (
            <p className='questionbox__button'>{isExpanded ? '-' : '+'}</p>
        );

        return (
            <div
                key={item.id}
                className={[
                    'questionbox',
                    isExpanded ? 'questionbox--white' : 'questionbox--grey',
                ].join(' ')}
                onClick={() => handleClick(index)}
            >
                <p className='questionbox__title'>
                    {item.label}
                    {icon}
                </p>
                {isExpanded &&
                    item.content.map((desc, index) => (
                        <div key={index} className='questionbox__description'>
                            {desc}
                        </div>
                    ))}
            </div>
        );
    });

    return <div>{renderedItems}</div>;
}

export default QuestionBox;
