import MauroFattore from '../assets/doctors/MauroFattore.jpg';
import HelenSheng from '../assets/doctors/HelenSheng.jpg';
import IbrahimMohammad from '../assets/doctors/IbrahimMohammad.jpg';

export const renderBio = (bioSelect, setBioState, openModal) => {
    if (bioSelect === 1) {
        setBioState(
            <>
                <h3 className='modal__title'>Dr. Mauro Fattore</h3>
                <div className='row'>
                    <div className='col-1-of-3'>
                        <img
                            className='modal__image'
                            src={MauroFattore}
                            alt='Mauro Fattore'
                        />
                    </div>
                    <div className='col-2-of-3'>
                        <p className='modal__description modal__description--first'>
                            Dr. Mauro Fattore completed a Bachelor of Science at
                            the University of Western Ontario. He continued on
                            to the University of Manitoba where he graduated
                            from the School of Dentistry in 1998.
                        </p>
                        <p className='modal__description'>
                            Dr. Fattore and his team pride themselves on taking
                            the time to really listen to each patient’s
                            concerns. He enjoys working with both adults and
                            children. His aim is to provide a comfortable and
                            pain-fee experience at every appointment.
                        </p>
                        <p className='modal__description'>
                            Dr. Fattore has developed a special interest in
                            implant surgery and cosmetic dentistry. He stays
                            abreast of the latest advances in dentistry through
                            numerous hours of continuing education, as well as
                            his affiliations which include:
                        </p>
                        <p className='modal__additionaltext'>
                            The Canadian Dental Association
                        </p>
                        <p className='modal__additionaltext'>
                            The Ontario Dental Association
                        </p>
                        <p className='modal__additionaltext'>
                            The Burlington Dental Academy
                        </p>
                        <p className='modal__additionaltext'>
                            The Toronto Implant Study Club
                        </p>
                    </div>
                </div>
            </>
        );
    } else if (bioSelect === 2) {
        setBioState(
            <>
                <h3 className='modal__title'>Dr. Helen Sheng</h3>
                <div className='row'>
                    <div className='col-1-of-3'>
                        <img className='modal__image' src={HelenSheng} alt='' />
                    </div>
                    <div className='col-2-of-3'>
                        <p className='modal__description modal__description--first'>
                            Dr. Helen Sheng obtained her Doctor of Dental
                            Surgery degree from the University of Toronto where
                            she graduated with Honours. Afterwards, she spent
                            time in New York City completing a hospital dental
                            residency. Dr. Sheng has also taught dental students
                            in Ethiopia and regularly volunteers her time
                            providing care to underserved communities while
                            participating in local and global healthcare
                            outreach programs.
                        </p>
                        <p className='modal__description'>
                            Dr. Sheng is committed to providing the highest
                            quality of care in a relaxed and comfortable
                            environment. She believes in a patient-centred
                            approach which focuses on clear communication,
                            honesty, compassion, and understanding the goals of
                            each individual.
                        </p>
                        <p className='modal__description'>
                            Dr. Sheng was born and raised in Toronto but now
                            proudly calls Burlington home. In her spare time,
                            she enjoys reading, trying new restaurants,
                            travelling with her partner, and spending time with
                            their puppy, Mochi.
                        </p>
                    </div>
                </div>
            </>
        );
    } else if (bioSelect === 3) {
        setBioState(
            <>
                <h3 className='modal__title'>Dr. Ibrahim Mohammad</h3>
                <div className='row'>
                    <div className='col-1-of-3'>
                        <img
                            className='modal__image'
                            src={IbrahimMohammad}
                            alt=''
                        />
                    </div>
                    <div className='col-2-of-3'>
                        <p className='modal__description modal__description--first'>
                            Dr. Ibrahim (Abe) Mohammad joined us at Millcroft
                            Dental Care in the summer of 2020. He provides
                            comprehensive dental care to patients of all ages.
                            Dr. Mohammad is passionate about enhancing people’s
                            oral health. He believes that by enriching our oral
                            health we can truly enjoy a healthy and comfortable
                            lifestyle.
                        </p>
                        <p className='modal__description'>
                            Whether your dental needs are simple or complex, Dr.
                            Mohammad will take the time to thoroughly review all
                            aspects of your oral health with you. He will work
                            with patients to come up with the ideal dental
                            treatment plan based on scientific evidence,
                            clinical expertise, and patient’s desires. Dr.
                            Mohammad understands that receiving dental treatment
                            has its challenges which can give people anxiety.
                            His friendly personality and gentle approach will
                            make people feel comfortable.
                        </p>
                        <p className='modal__description__alt'>
                            Dr. Mohammad grew up in Hamilton, Ontario. He
                            received his Bachelor of Science from the University
                            of Waterloo in 2008, and went on to complete his
                            Master of Science from the University of Toronto in
                            2010. He studied dentistry at the University of
                            Minnesota and graduated with a Doctor of Dental
                            Surgery in 2015. After graduation he moved to
                            Vancouver Island and enjoyed working there as a
                            dentist for close to 5 years. In 2020 he moved back
                            to Ontario. Dr. Mohammad always seeks to upgrade his
                            skills by taking continuing education courses from
                            Dr. John Kois, Dr. Frank Spear, and Dr. Gordon
                            Christensen.
                        </p>
                        <p className='modal__description'>
                            Outside the office, Dr. Mohammad enjoys hiking,
                            travelling, and playing sports.
                        </p>
                    </div>
                </div>
            </>
        );
    }

    openModal();
};
