function DrawerToggle({ onToggle }) {
    return (
        <div className='drawertoggle' onClick={onToggle}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default DrawerToggle;
