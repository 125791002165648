import Backdrop from './Backdrop';

function Modal({ show, onClose, office, children }) {
    return (
        <>
            <Backdrop show={show} clicked={onClose} />
            <div
                className={['modal', office ? 'modal__office' : '']
                    .join(' ')
                    .trimEnd()}
                style={{
                    transform: show ? 'translateY(0)' : 'translateY(-100vh)',
                    opacity: show ? '1' : '0',
                }}
            >
                <div className='modal__closebutton' onClick={onClose}>
                    &times;
                </div>
                {children}
            </div>
        </>
    );
}

export default Modal;
