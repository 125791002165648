import { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import useRecentBlog from '../hooks/use-recent-blog';
import useContactForm from '../hooks/use-contact-form';
import useBioModal from '../hooks/use-bio-modal';
import Layout from '../components/Layout';
import Modal from '../components/Modal';
import HomeAboutSection from '../components/HomeAboutSection';
import HomeWelcomeSection from '../components/HomeWelcomeSection';
import HomeDoctorsSection from '../components/HomeDoctorsSection';
import HomePatientFormSection from '../components/HomePatientFormSection';
import HomeContactSection from '../components/HomeContactSection';
import HomeReviewsSection from '../components/HomeReviewsSection';
// import { displayBlogs } from '../utils/displayBlogs';

import Building from '../assets/building.jpg';

const Home = () => {
    // const { blogs } = useRecentBlog();
    const { name, email, message, loading, onChange, onSubmit } =
        useContactForm();
    const { modalOpen, openModal, closeModal, bioState, setBioState } =
        useBioModal();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            title='Millcroft Dental Care | Home'
            content="Millcroft Dental Care is a dentistry with incredible staff. It's a place where you can get a wide variety of dental services, and all of them are done through the care of highly qualified staff members."
            path=''
        >
            <div className='home'>
                {modalOpen && (
                    <Modal show={modalOpen} onClose={closeModal}>
                        {bioState}
                    </Modal>
                )}
                <section className='home__header'>
                    <div className='home__header__cta'>
                        <Link
                            className='home__header__cta__button'
                            to='/contact-us'
                        >
                            Contact Us
                        </Link>
                    </div>
                    <p className='home__header__buttontext'>
                        Call or message us today to schedule your next visit
                    </p>
                    <div className='home__header__image'></div>
                </section>
                <HomeAboutSection />
                <HomeWelcomeSection />
                <HomeDoctorsSection
                    setBioState={setBioState}
                    openModal={openModal}
                />
                <HomePatientFormSection />
                <HomeContactSection
                    name={name}
                    email={email}
                    message={message}
                    loading={loading}
                    onChange={onChange}
                    onSubmit={onSubmit}
                />
                <HomeReviewsSection />
                <section className='home__building'>
                    <img
                        className='home__building__image'
                        src={Building}
                        alt='Millcroft Dental Care Building'
                    />
                </section>
                {/* {blogs && (
                    <section className='home__recentblogs'>
                        <div className='blogdetail__blog'>
                            <h2 className='blogdetail__headingSecondary'>
                                Our Recent Insights
                            </h2>
                            {displayBlogs(blogs)}
                        </div>
                    </section>
                )} */}
            </div>
        </Layout>
    );
};

export default Home;
