import HomeContactInfo from './HomeContactInfo';
import HomeContactForm from './HomeContactForm';

function HomeContactSection({
    name,
    email,
    message,
    loading,
    onChange,
    onSubmit,
}) {
    return (
        <section className='home__contact'>
            <h2 className='home__contact__headerprimary'>
                Ready for your dream smile?
            </h2>
            <h3 className='home__contact__headersecondary'>
                Achieve it with our professionals at Millcroft Dental Care
            </h3>
            <div className='row'>
                <div className='col-2-of-3'>
                    <HomeContactInfo />
                </div>
                <div className='col-1-of-3'>
                    <HomeContactForm
                        name={name}
                        email={email}
                        message={message}
                        loading={loading}
                        onChange={onChange}
                        onSubmit={onSubmit}
                    />
                </div>
            </div>
        </section>
    );
}

export default HomeContactSection;
