import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import DrawerToggle from './DrawerToggle';
import Logo from './Logo';
import { FaPhoneAlt } from 'react-icons/fa';

function Navbar({ onToggle }) {
    let links = 'navbar__link navbar__link--light navbar__tabport';

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const renderDropdown = () => {
        if (isDropdownOpen) {
            return (
                <div className='list'>
                    <ul className='list__items'>
                        <li className='list__item'>
                            <NavLink
                                className='list__link'
                                to='/our-services/childrens-dentistry'
                            >
                                Children's Dentistry
                            </NavLink>
                        </li>
                        <li className='list__item'>
                            <NavLink
                                className='list__link'
                                to='/our-services/emergency-care'
                            >
                                Emergency Care
                            </NavLink>
                        </li>
                        <li className='list__item'>
                            <NavLink
                                className='list__link'
                                to='/our-services/general-dentistry'
                            >
                                General Dentistry
                            </NavLink>
                        </li>
                        <li className='list__item'>
                            <NavLink
                                className='list__link'
                                to='/our-services/implants'
                            >
                                Implants
                            </NavLink>
                        </li>
                        <li className='list__item'>
                            <NavLink
                                className='list__link'
                                to='/our-services/invisalign'
                            >
                                Invisalign
                            </NavLink>
                        </li>
                        <li className='list__item'>
                            <NavLink
                                className='list__link'
                                to='/our-services/smile-makeover'
                            >
                                Smile Makeover
                            </NavLink>
                        </li>
                        <li className='list__item'>
                            <NavLink
                                className='list__link'
                                to='/our-services/snoring-treatment'
                            >
                                Snoring Treatment
                            </NavLink>
                        </li>
                        <li className='list__item list__item--last'>
                            <NavLink
                                className='list__link'
                                to='/our-services/wisdom-teeth'
                            >
                                Wisdom Teeth
                            </NavLink>
                        </li>
                    </ul>
                </div>
            );
        }
    };

    return (
        <div className='navbar'>
            <div className='navbar__top'>
                <div className='navbar__logo'>
                    <Logo />
                </div>
                <div className='navbar__info'>
                    <div className='navbar__info__cta'>
                        <a
                            className='navbar__info__cta__phone'
                            href='tel:(905) 315-9888'
                        >
                            <FaPhoneAlt />{' '}
                            <span className='navbar__info__cta__phone--margin'>
                                (905) 315-9888
                            </span>
                        </a>
                        <Link
                            className='navbar__info__cta__button'
                            to='/contact-us'
                        >
                            Book An Appointment
                        </Link>
                    </div>
                    <div className='navbar__info__contact'></div>
                </div>
            </div>
            <div className='navbar__bottom'>
                <div className='navbar__links'>
                    <NavLink className={links} to='/'>
                        Home
                    </NavLink>
                    <NavLink className={links} to='/our-office'>
                        Our Office
                    </NavLink>
                    <div
                        className='navbar__dropdown'
                        onMouseEnter={() => setIsDropdownOpen(true)}
                        onMouseLeave={() => setIsDropdownOpen(false)}
                    >
                        <NavLink className={links} to='/our-services'>
                            Our Services
                        </NavLink>
                        {renderDropdown()}
                    </div>
                    <NavLink className={links} to='/our-blog'>
                        Our Blog
                    </NavLink>
                    <NavLink className={links} to='/faq'>
                        FAQ
                    </NavLink>
                    <NavLink className={links} to='/patient-form'>
                        Patient Form
                    </NavLink>
                    <NavLink className={links} to='/gallery'>
                        Gallery
                    </NavLink>
                    <NavLink
                        className='navbar__link navbar__link--last navbar__link--light navbar__tabport'
                        to='/contact-us'
                    >
                        Contact Us
                    </NavLink>
                    <DrawerToggle onToggle={onToggle} />
                </div>
            </div>
        </div>
    );
}

export default Navbar;
