import { useEffect } from 'react';
import useContactForm from '../hooks/use-contact-form';
import Layout from '../components/Layout';
import HeaderInfo from '../components/HeaderInfo';
import ServiceList from '../components/ServiceList';
import ContactForm from '../components/ContactForm';
import ContactBox from '../components/ContactBox';

function ServicesPage() {
    const { name, email, message, loading, onChange, onSubmit } =
        useContactForm();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            title='Millcroft Dental Care | Our Services'
            content="Millcroft Dental Care has a wide variety of services that it offers, this includes things like children's dentistry, emergency care, general dentistry, implants, invisalign, smile makeover, snoring treatment, and wisdom teeth."
            path='our-services'
        >
            <div className='services'>
                <div className='services__header'>
                    <HeaderInfo title='Our Services' link='Our Services' />
                </div>
                <div className='row'>
                    <div className='col-2-of-3'>
                        <h2 className='services__heading'>
                            Create your Dream Smile
                        </h2>
                        <p className='services__paragraph'>
                            At Millcroft Dental Care, patients love our relaxed,
                            friendly atmosphere. Whether you’re stopping by for
                            a cleaning, bringing in the kids for a check up,
                            getting a filling or are doing some restorative
                            work, we are committed to doing everything we can to
                            make you comfortable. Our caring staff do their best
                            to ensure the best possible experience on your road
                            to great dental health.
                        </p>
                        <ServiceList />
                        <div className='row'>
                            <ContactBox />
                        </div>
                    </div>
                    <div className='col-1-of-3'>
                        <ContactForm
                            name={name}
                            email={email}
                            message={message}
                            onChange={onChange}
                            onSubmit={onSubmit}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ServicesPage;
