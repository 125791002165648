function HomeWelcomeSection() {
    return (
        <section className='home__welcome'>
            <h2 className='heading__secondary'>
                Welcome to Millcroft Dental Care
            </h2>
            <p className='paragraph paragraph--spaced paragraph--padding paragraph--center'>
                We hope you will feel at home when you visit Millcroft Dental
                Care. We welcome every patient as a member of our family because
                we want your dental experience to be stress-free and easy. Dr.
                Fattore and his associates know how to keep your children
                smiling in the children’s play area as well as the dental chair.
                He has undergone advanced training in implant and cosmetic
                dentistry and is able to perform most procedures in-house to
                help give you that healthy smile you've always wanted in the
                most efficient way possible. A confident smile boosts
                self-esteem and will make you appear friendly, attractive, and
                successful. We use the leading teeth whitening system to make
                your teeth shine. Skillfully crafted, fine porcelain veneers can
                transform crooked, chipped, and discolored teeth into a smile
                that will make you look as good as you feel.
            </p>
        </section>
    );
}

export default HomeWelcomeSection;
