import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import OfficePage from './pages/OfficePage';
import ServicesPage from './pages/ServicesPage';
import ChildrensDentistryPage from './pages/services/ChildrensDentistryPage';
import EmergencyCarePage from './pages/services/EmergencyCarePage';
import GeneralDentistryPage from './pages/services/GeneralDentistryPage';
import ImplantsPage from './pages/services/ImplantsPage';
import InvisalignPage from './pages/services/InvisalignPage';
import SmileMakeoverPage from './pages/services/SmileMakeoverPage';
import SnoringTreatmentPage from './pages/services/SnoringTreatmentPage';
import WisdomTeethPage from './pages/services/WisdomTeethPage';
import BlogPage from './pages/BlogPage';
import BlogDetailPage from './pages/BlogDetailPage';
import FAQPage from './pages/FAQPage';
import PatientFormPage from './pages/PatientFormPage';
import GalleryPage from './pages/GalleryPage';
import ContactPage from './pages/ContactPage';

import './sass/main.scss';

function App() {
    return (
        <Router>
            <Routes>
                <Route index element={<HomePage />} />
                <Route path='our-office' element={<OfficePage />} />
                <Route path='our-services'>
                    <Route index element={<ServicesPage />} />
                    <Route
                        path='childrens-dentistry'
                        element={<ChildrensDentistryPage />}
                    />
                    <Route
                        path='emergency-care'
                        element={<EmergencyCarePage />}
                    />
                    <Route
                        path='general-dentistry'
                        element={<GeneralDentistryPage />}
                    />
                    <Route path='implants' element={<ImplantsPage />} />
                    <Route path='invisalign' element={<InvisalignPage />} />
                    <Route
                        path='smile-makeover'
                        element={<SmileMakeoverPage />}
                    />
                    <Route
                        path='snoring-treatment'
                        element={<SnoringTreatmentPage />}
                    />
                    <Route path='wisdom-teeth' element={<WisdomTeethPage />} />
                </Route>
                <Route path='our-blog'>
                    <Route index element={<BlogPage />} />
                    <Route path=':slug' element={<BlogDetailPage />} />
                </Route>
                <Route path='faq' element={<FAQPage />} />
                <Route path='patient-form' element={<PatientFormPage />} />
                <Route path='gallery' element={<GalleryPage />} />
                <Route path='contact-us' element={<ContactPage />} />
            </Routes>
        </Router>
    );
}

export default App;
