import { useEffect } from 'react';
import useContactForm from '../../hooks/use-contact-form';
import Layout from '../../components/Layout';
import HeaderInfo from '../../components/HeaderInfo';
import ContactForm from '../../components/ContactForm';
import ContactBox from '../../components/ContactBox';

function ImplantsPage() {
    const { name, email, message, loading, onChange, onSubmit } =
        useContactForm();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            title='Millcroft Dental Care | Our Services - Implants'
            content='Dental implants are titanium or ceramic made “posts” that support natural-looking restorations, like porcelain/ceramic dental crowns or bridges.Dental implants are changing the way people live! With them, people are rediscovering the comfort and confidence to eat, speak, laugh and enjoy life. If, like many others, you feel implant dentistry is the choice for you, we ask that you undergo a dental/radiographic examination and health history. During these consultation visits, your specific needs and considerations will be addressed by our dentists.Your questions and concerns are important to us and our team will work with you very closely to help make your procedure a success.'
            path='our-services/implants'
        >
            <div className='services'>
                <div className='services__header-implants'>
                    <HeaderInfo title='Implants' link='Implants' services />
                </div>
                <div className='row'>
                    <div className='col-2-of-3'>
                        <h2 className='services__heading'>Dental Implants</h2>
                        <h3 className='services__headingSecondary'>
                            Titanium Implants and Ceramic Implants
                        </h3>
                        <p className='services__paragraph'>
                            Dental implants are titanium or ceramic made “posts”
                            that support natural-looking restorations, like
                            porcelain/ceramic dental crowns or bridges.Dental
                            implants are changing the way people live! With
                            them, people are rediscovering the comfort and
                            confidence to eat, speak, laugh and enjoy life. If,
                            like many others, you feel implant dentistry is the
                            choice for you, we ask that you undergo a
                            dental/radiographic examination and health history.
                            During these consultation visits, your specific
                            needs and considerations will be addressed by our
                            dentists.Your questions and concerns are important
                            to us and our team will work with you very closely
                            to help make your procedure a success.
                        </p>
                        <div className='row'>
                            <ContactBox />
                        </div>
                    </div>
                    <div className='col-1-of-3'>
                        <ContactForm
                            name={name}
                            email={email}
                            message={message}
                            onChange={onChange}
                            onSubmit={onSubmit}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ImplantsPage;
