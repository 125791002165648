import { Link } from 'react-router-dom';

function ServiceList() {
    return (
        <ul className='services__list'>
            <li className='services__list__item'>
                <Link
                    className='services__list__link'
                    to='/our-services/childrens-dentistry'
                >
                    Children's Dentistry
                </Link>
            </li>
            <li className='services__list__item'>
                <Link
                    className='services__list__link'
                    to='/our-services/emergency-care'
                >
                    Emergency Care
                </Link>
            </li>
            <li className='services__list__item'>
                <Link
                    className='services__list__link'
                    to='/our-services/general-dentistry'
                >
                    General Dentistry
                </Link>
            </li>
            <li className='services__list__item'>
                <Link
                    className='services__list__link'
                    to='/our-services/implants'
                >
                    Implants
                </Link>
            </li>
            <li className='services__list__item'>
                <Link
                    className='services__list__link'
                    to='/our-services/invisalign'
                >
                    Invisalign
                </Link>
            </li>
            <li className='services__list__item'>
                <Link
                    className='services__list__link'
                    to='/our-services/smile-makeover'
                >
                    Smile Makeover
                </Link>
            </li>
            <li className='services__list__item'>
                <Link
                    className='services__list__link'
                    to='/our-services/snoring-treatment'
                >
                    Snoring Treatment
                </Link>
            </li>
            <li className='services__list__item'>
                <Link
                    className='services__list__link'
                    to='/our-services/wisdom-teeth'
                >
                    Wisdom Teeth
                </Link>
            </li>
        </ul>
    );
}

export default ServiceList;
