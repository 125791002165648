import GoogleReviewsButton from '../assets/google_review_button.png';
import FiveStars from '../assets/five_stars.png';

function HomeReviewsSection() {
    return (
        <section className='home__reviews'>
            <div className='home__reviews__google'>
                <div className='row'>
                    <div className='col-1-of-3 home__reviews__center'>
                        <a href='https://www.google.com/search?q=millcroft+dental+care&oq=millcroft+dental+care&aqs=chrome..69i57j69i61l3.2533j0j1&sourceid=chrome&ie=UTF-8#lrd=0x882b6122ee77dfb3:0xa86f6389144d9b2d,1,,,'>
                            <img
                                className='home__reviews__google__image'
                                src={GoogleReviewsButton}
                                alt='Google review button'
                            />
                        </a>
                    </div>
                    <div className='col-2-of-3'>
                        <p className='home__reviews__paragraph'>
                            I have always received wonderful care from Dr.
                            Fatore at this office. The staff treats my allergies
                            and dental anxieties with sensitivity and safety. I
                            am especially pleased that the office is latex free.
                        </p>
                        <div className='home__reviews__rating'>
                            <img
                                className='home__reviews__rating__image'
                                src={FiveStars}
                                alt='Five Star Rating'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HomeReviewsSection;
