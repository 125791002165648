import { Link } from 'react-router-dom';
import { FaPhoneAlt, FaChevronRight } from 'react-icons/fa';

function HeaderInfo({ title, services, link }) {
    return (
        <div className='headerinfo'>
            <div>
                <h1 className='headerinfo__title'>{title}</h1>
                <div>
                    <Link
                        className='headerinfo__breadcrumb headerinfo__breadcrumb__home'
                        to='/'
                    >
                        Home
                    </Link>
                    {services && (
                        <span className='headerinfo__breadcrumb headerinfo__breadcrumb__current'>
                            <FaChevronRight
                                style={{
                                    marginRight: '-10px',
                                    marginLeft: '4px',
                                    paddingTop: '6px',
                                }}
                            />
                            <FaChevronRight style={{ paddingTop: '6px' }} />
                            <Link
                                className='headerinfo__breadcrumb headerinfo__breadcrumb__home'
                                to='/our-services'
                            >
                                Services
                            </Link>
                        </span>
                    )}
                    <span className='headerinfo__breadcrumb headerinfo__breadcrumb__current'>
                        <FaChevronRight
                            style={{
                                marginRight: '-10px',
                                marginLeft: '4px',
                                paddingTop: '6px',
                            }}
                        />
                        <FaChevronRight style={{ paddingTop: '6px' }} /> {link}
                    </span>
                </div>
            </div>
            <div>
                <a className='headerinfo__phone' href='tel:(905) 315-9888'>
                    <FaPhoneAlt />
                    <span className='headerinfo__phone--margin'>
                        (905) 315-9888
                    </span>
                </a>
            </div>
        </div>
    );
}

export default HeaderInfo;
