import { useEffect } from 'react';
import useContactForm from '../../hooks/use-contact-form';
import Layout from '../../components/Layout';
import HeaderInfo from '../../components/HeaderInfo';
import ContactForm from '../../components/ContactForm';
import ContactBox from '../../components/ContactBox';

function EmergencyCarePage() {
    const { name, email, message, loading, onChange, onSubmit } =
        useContactForm();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            title='Millcroft Dental Care | Our Services - Emergency Care'
            content='Soreness and minor hot and cold sensitivity are common symptoms following dental treatment. This normally resolves within a few days. Spontaneous tooth pain is more concerning and should be addressed as soon as possible. If you are in pain or have any concerns, please call our office to arrange an appointment. Tylenol and saltwater rinse may help as a short–term solution.'
            path='our-services/emergency-care'
        >
            <div className='services'>
                <div className='services__header-emergency-care'>
                    <HeaderInfo
                        title='Emergency Care'
                        link='Emergency Care'
                        services
                    />
                </div>
                <div className='row'>
                    <div className='col-2-of-3'>
                        <h2 className='services__heading'>
                            Get Any Dental Issue Treated with Care
                        </h2>
                        <h3 className='services__headingSecondary'>
                            Tooth Ache
                        </h3>
                        <p className='services__paragraph'>
                            Soreness and minor hot and cold sensitivity are
                            common symptoms following dental treatment. This
                            normally resolves within a few days. Spontaneous
                            tooth pain is more concerning and should be
                            addressed as soon as possible. If you are in pain or
                            have any concerns, please call our office to arrange
                            an appointment. Tylenol and saltwater rinse may help
                            as a short–term solution.
                        </p>
                        <h3 className='services__headingSecondary'>
                            Knocked Out Permanent Tooth
                        </h3>
                        <p className='services__paragraph'>
                            If an adult tooth has completely come out (usually
                            due to trauma) immediate treatment is required. Make
                            sure to hold the tooth by the crown (top) and not
                            the root. If possible gently rinse off debris (do
                            not clean or scrub) and reinsert the tooth in the
                            socket. Hold the tooth in place and come to the
                            office. If the tooth cannot be reinserted, place it
                            in a cup of milk and come directly to the office.
                        </p>
                        <h3 className='services__headingSecondary'>
                            Broken Teeth
                        </h3>
                        <p className='services__paragraph'>
                            Broken teeth should be treated as soon as possible
                            to prevent further damage and unnecessary pain. If
                            you have any swelling in the area, cold ice packs
                            can help. Call us as soon as possible to book an
                            appointment.
                        </p>
                        <h3 className='services__headingSecondary'>
                            Bleeding or Swelling Following Dental Treatment
                        </h3>
                        <p className='services__paragraph'>
                            If you experience abnormal bleeding or swelling
                            beyond what was described by your dentist, please
                            call us immediately. For after hours care, please go
                            to the emergency hospital.
                        </p>
                        <div className='row'>
                            <ContactBox />
                        </div>
                    </div>
                    <div className='col-1-of-3'>
                        <ContactForm
                            name={name}
                            email={email}
                            message={message}
                            onChange={onChange}
                            onSubmit={onSubmit}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default EmergencyCarePage;
