import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Layout from '../components/Layout';
import HeaderInfo from '../components/HeaderInfo';
import BlogPost from '../components/BlogPost';
import { formatDate } from '../utils/formatDate';

function BlogDetailPage() {
    const [blogPosts, setBlogPosts] = useState([]);
    const [blogPost, setBlogPost] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            const config = {
                headers: {
                    Accept: 'application/json',
                },
            };

            try {
                const res = await axios.get('/api/blog/', config);

                if (res.status === 200) {
                    setBlogPosts(res.data);
                }
            } catch (err) {}
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const config = {
                headers: {
                    Accept: 'application/json',
                },
            };

            try {
                const res = await axios.get(`/api/blog/${slug}/`, config);

                if (res.status === 200) {
                    setBlogPost(res.data);
                }
            } catch (err) {}
        };

        fetchData();
    }, [slug]);

    const renderLatestBlogPosts = () => {
        if (blogPosts.length > 0) {
            return (
                <div className='row'>
                    <div className='col-1-of-3'>
                        {blogPosts[0] && (
                            <BlogPost
                                title={blogPosts[0].title}
                                thumbnail={blogPosts[0].thumbnail}
                                thumbnail_alt={blogPosts[0].thumbnail_alt}
                                excerpt={blogPosts[0].excerpt}
                                date={blogPosts[0].date_updated}
                                link={blogPosts[0].slug}
                            />
                        )}
                    </div>
                    <div className='col-1-of-3'>
                        {blogPosts[1] && (
                            <BlogPost
                                title={blogPosts[1].title}
                                thumbnail={blogPosts[1].thumbnail}
                                thumbnail_alt={blogPosts[1].thumbnail_alt}
                                excerpt={blogPosts[1].excerpt}
                                date={blogPosts[1].date_updated}
                                link={blogPosts[1].slug}
                            />
                        )}
                    </div>
                    <div className='col-1-of-3'>
                        {blogPosts[2] && (
                            <BlogPost
                                title={blogPosts[2].title}
                                thumbnail={blogPosts[2].thumbnail}
                                thumbnail_alt={blogPosts[2].thumbnail_alt}
                                excerpt={blogPosts[2].excerpt}
                                date={blogPosts[2].date_updated}
                                link={blogPosts[2].slug}
                            />
                        )}
                    </div>
                </div>
            );
        }
    };

    const renderBlogPost = () => ({
        __html: blogPost.content,
    });

    return (
        <Layout
            title={`Millcroft Dental Care | ${
                blogPost !== null ? blogPost.title : 'Blog Post'
            }`}
            content={`${
                blogPost !== null
                    ? blogPost.excerpt
                    : 'Check out this blog post'
            }`}
            path={`our-blog/${slug}`}
        >
            <div className='blogpost__post'>
                <div className='blog__header'>
                    <HeaderInfo title='Our Blog' link='Our Blog' />
                </div>

                {blogPost !== null && (
                    <>
                        <h1 className='blogdetail__title'>{blogPost.title}</h1>

                        <div className='blogdetail__display'>
                            <img
                                className='blogdetail__display__image'
                                src={blogPost.showcase_photo}
                                alt={`${blogPost.showcase_photo_alt}`}
                            />
                        </div>
                        <p className='blogdetail__date'>
                            {formatDate(blogPost.date_created)}
                        </p>

                        <div
                            className='blogdetail__content'
                            dangerouslySetInnerHTML={renderBlogPost()}
                        />

                        <div className='blogdetail__blog'>
                            <h2 className='blogdetail__headingSecondary'>
                                Our Recent Insights
                            </h2>
                            {renderLatestBlogPosts()}
                        </div>
                    </>
                )}
            </div>
        </Layout>
    );
}

export default BlogDetailPage;
