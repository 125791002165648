import { useEffect } from 'react';
import Layout from '../../components/Layout';
import HeaderInfo from '../../components/HeaderInfo';
import ContactBox from '../../components/ContactBox';
import ReactCompareImage from 'react-compare-image';

import AlignerProfiles from '../../assets/AlignerProfiles.png';
import AlignerBlueBackground from '../../assets/AlignerBlueBackground.png';
import InvisalignProviderLogo from '../../assets/InvisalignProviderLogo.png';

function InvisalignPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            title='Millcroft Dental Care | Our Services - Invisalign'
            content='Traditional orthodontics involves the use of braces that are bonded to the teeth. These braces allow us to control the teeth more accurately so that we can fine tune their relationship to one another and create that beautiful smile. An initial orthodontic evaluation should occur at the first sign of orthodontic problems or by age 7. At this early age, braces may not be needed but identification of many growth abnormalities can be identified and corrected through early intervention procedures.'
            path='our-services/invisalign'
        >
            <div className='services'>
                <div className='services__header-invisalign'>
                    <HeaderInfo
                        title='Straight Teeth'
                        link='Invisalign'
                        services
                    />
                </div>
                <div className='row'>
                    <h3 className='services__headingSecondary services__marginTop'>
                        Traditional Braces
                    </h3>
                    <div className='col-1-of-2'>
                        <p className='services__paragraph'>
                            Traditional orthodontics involves the use of braces
                            that are bonded to the teeth. These braces allow us
                            to control the teeth more accurately so that we can
                            fine tune their relationship to one another and
                            create that beautiful smile. An initial orthodontic
                            evaluation should occur at the first sign of
                            orthodontic problems or by age 7. At this early age,
                            braces may not be needed but identification of many
                            growth abnormalities can be identified and corrected
                            through early intervention procedures.
                        </p>
                    </div>
                    <div className='col-1-of-2'>
                        <div className='services__display'>
                            <img
                                className='services__display__image'
                                src={AlignerProfiles}
                                alt='Aligner profiles'
                            />
                        </div>
                    </div>
                </div>
                <section className='services__comparison'>
                    <div className='row'>
                        <div className='col-1-of-3'>
                            <h3 className='services__headingSecondary services__headingSecondary--white'>
                                Invisalign
                            </h3>
                            <ul className='services__list'>
                                <li className='services__list__item'>
                                    Clear aligners are virtually invisible
                                </li>
                                <li className='services__list__item'>
                                    Smooth comfortable plastic aligners
                                </li>
                                <li className='services__list__item'>
                                    Aligners are removable for easy cleaning
                                </li>
                                <li className='services__list__item'>
                                    Eat whatever you want
                                </li>
                            </ul>
                        </div>
                        <div className='col-1-of-3'>
                            <ReactCompareImage
                                leftImage='/images/nobraces_image.jpg'
                                rightImage='/images/braces_image.jpg'
                            />
                        </div>
                        <div className='col-1-of-3'>
                            <div className='services__marginLeft'>
                                <h3 className='services__headingSecondary services__headingSecondary--white'>
                                    Braces
                                </h3>
                                <ul className='services__list'>
                                    <li className='services__list__item'>
                                        Requires brackets and wires
                                    </li>
                                    <li className='services__list__item'>
                                        Wires can poke and irritate mouth
                                    </li>
                                    <li className='services__list__item'>
                                        Brushing and flossing require more
                                        effort
                                    </li>
                                    <li className='services__list__item'>
                                        Many food restrictions
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='row'>
                    <h3 className='services__headingSecondary services__marginTop'>
                        Invisalign
                    </h3>
                    <div className='col-1-of-2'>
                        <p className='services__paragraph'>
                            (CLEAR BRACES) technology, paving the way towards
                            better smiles without the need for metal braces. The
                            process of straightening teeth with Invisalign
                            incorporates digital images and innovative computer
                            technology to design a series of customized
                            aligners. With every progression, teeth are
                            continually and gently encouraged into the desired
                            position. Perhaps one of the most attractive aspects
                            of Invisalign treatment is that aligners are
                            removable and there are no metal brackets are
                            cemented to teeth.
                        </p>
                    </div>
                    <div className='col-1-of-2'>
                        <div className='services__display'>
                            <img
                                className='services__display__image'
                                src={AlignerBlueBackground}
                                alt='Aligner with blue background'
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='services__invisalignlogo'>
                        <img
                            className='services__invisalignlogo__image'
                            src={InvisalignProviderLogo}
                            alt='Invisalign Provider Logo'
                        />
                    </div>
                </div>
                <div className='row'>
                    <ContactBox />
                </div>
            </div>
        </Layout>
    );
}

export default InvisalignPage;
