import useGoogleMap from '../hooks/use-google-map';

function HomeContactInfo() {
    const { googleMap } = useGoogleMap();

    return (
        <>
            <div className='row'>
                <div className='col-1-of-2'>
                    <h3 className='home__contact__headertertiary'>
                        Millcroft Dental Care
                    </h3>
                    <p className='paragraph paragraph--grey'>
                        2020 Appleby Line, Unit B4A
                    </p>
                    <p className='paragraph paragraph--grey'>
                        Burlington, ON L7L 6M6
                    </p>
                    <p className='paragraph paragraph--margin paragraph--grey'>
                        Phone: (905) 315-9888
                    </p>
                    <p className='paragraph paragraph--margin paragraph--grey'>
                        Email: info@millcroftdentalcare.com
                    </p>
                </div>
                <div className='col-1-of-2'>
                    <p className='paragraph paragraph--bigmargin paragraph--grey'>
                        Monday 8:00 AM - 8:00 PM
                    </p>
                    <p className='paragraph paragraph--grey'>
                        Tuesday 8:00 AM - 8:00 PM
                    </p>
                    <p className='paragraph paragraph--grey'>
                        Wednesday 8:00 AM - 8:00 PM
                    </p>
                    <p className='paragraph paragraph--grey'>
                        Thursday 8:00 AM - 8:00 PM
                    </p>
                    <p className='paragraph paragraph--grey'>
                        Friday 8:00 AM - 3:00 PM
                    </p>
                    <p className='paragraph paragraph--grey'>
                        Saturday 9:00 AM - 3:00 PM
                    </p>
                    <p className='paragraph paragraph--grey'>Sunday Closed</p>
                </div>
            </div>
            <div className='home__contact__map'>{googleMap}</div>
        </>
    );
}

export default HomeContactInfo;
