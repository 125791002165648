import { useEffect } from 'react';
import Layout from '../components/Layout';
import HeaderInfo from '../components/HeaderInfo';

function PatientFormPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            title='Millcroft Dental Care | Patient Form'
            content='Before a patient receives a service at Millcroft Dental Care, they must first fill out a patient form. To speed up the process, patients can always print out the patient form ahead of time, fill it out, and bring it with them to their appoitment.'
            path='patient-form'
        >
            <div className='patientform'>
                <div className='patientform__header'>
                    <HeaderInfo title='Patient Form' link='Patient Form' />
                </div>
                <h2 className='patientform__title'>
                    Please take a few minutes to fill out and email the patient
                    forms below, ahead of your first visit, to save you time!
                </h2>
                <div className='row'>
                    <div className='col-1-of-2'>
                        <div className='patientform__instructions'>
                            <p className='patientform__instructions__text'>
                                Instructions
                            </p>
                            <ol className='patientform__list'>
                                <li className='patientform__list__item'>
                                    Download the PDF
                                </li>
                                <li className='patientform__list__item'>
                                    Fill in the information
                                </li>
                                <li className='patientform__list__item'>
                                    Save the PDF
                                </li>
                                <li className='patientform__list__item'>
                                    Email them to info@millcroftdentalcare.com
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div className='col-1-of-2'>
                        <div className='patientform__download'>
                            <p className='patientform__download__text'>
                                Download the file:{' '}
                            </p>
                            <a
                                className='patientform__download__link'
                                href={
                                    process.env.NODE_ENV !== 'development'
                                        ? '/static/patientform.pdf'
                                        : '/patientform.pdf'
                                }
                                download
                            >
                                Patient Form
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default PatientFormPage;
