import { useEffect } from 'react';
import useContactForm from '../../hooks/use-contact-form';
import Layout from '../../components/Layout';
import HeaderInfo from '../../components/HeaderInfo';
import ContactForm from '../../components/ContactForm';
import ContactBox from '../../components/ContactBox';

function SmileMakeoverPage() {
    const { name, email, message, loading, onChange, onSubmit } =
        useContactForm();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            title='Millcroft Dental Care | Our Services - Smile Makeover'
            content='Teeth sometimes lose structural integrity, whether due to cavities, breakage, or cracked old filling material. Our dentists utilize the most advanced composite resin and ceramic materials to restore a tooth to natural and functional aesthetics. Whether you’re looking to improve the structure of your teeth or simply to feel more confident about your smile, the dentists at Millcroft Dental Care can offer a number of effective solutions.'
            path='our-services/smile-makeover'
        >
            <div className='services'>
                <div className='services__header-smile-makeover'>
                    <HeaderInfo
                        title='Smile Makeover'
                        link='Smile Makeover'
                        services
                    />
                </div>
                <div className='row'>
                    <div className='col-2-of-3'>
                        <h2 className='services__heading'>
                            Create that Dream Smile
                        </h2>
                        <h3 className='services__headingSecondary'>
                            Improve your Smile
                        </h3>
                        <p className='services__paragraph'>
                            Teeth sometimes lose structural integrity, whether
                            due to cavities, breakage, or cracked old filling
                            material. Our dentists utilize the most advanced
                            composite resin and ceramic materials to restore a
                            tooth to natural and functional aesthetics. Whether
                            you’re looking to improve the structure of your
                            teeth or simply to feel more confident about your
                            smile, the dentists at Millcroft Dental Care can
                            offer a number of effective solutions.
                        </p>
                        <h3 className='services__headingSecondary'>
                            Treat Structural Issues
                        </h3>
                        <p className='services__paragraph'>
                            Small structural issues can be dealt with using
                            bonded direct composite resin restorations
                            (Bonding). Larger structural compromise requires us
                            to use a stronger more resistant material. We use
                            E.Max and Zirconia ceramic crowns that are highly
                            aesthetic and very strong. Our solutions will help
                            protect your teeth and ensure that the chewing you
                            do will cause minimal damage.
                        </p>
                        <h3 className='services__headingSecondary'>
                            Book your Appointment Today
                        </h3>
                        <p className='services__paragraph'>
                            To find out which solutions and materials will best
                            be suited for your needs call us to set-up an
                            appointment.
                        </p>
                        <div className='row'>
                            <ContactBox />
                        </div>
                    </div>
                    <div className='col-1-of-3'>
                        <ContactForm
                            name={name}
                            email={email}
                            message={message}
                            onChange={onChange}
                            onSubmit={onSubmit}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default SmileMakeoverPage;
