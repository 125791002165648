import { useEffect } from 'react';
import useContactForm from '../../hooks/use-contact-form';
import Layout from '../../components/Layout';
import HeaderInfo from '../../components/HeaderInfo';
import ContactForm from '../../components/ContactForm';
import ContactBox from '../../components/ContactBox';

function SnoringTreatmentPage() {
    const { name, email, message, loading, onChange, onSubmit } =
        useContactForm();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            title='Millcroft Dental Care | Our Services - Snoring Treatment'
            content='Snoring happens when turbulent air flows through the upper airway causing the tissues to vibrate. 
            This is a common problem that interferes with a good nights sleep and is disruptive to sleeping 
            partners. Sometimes snoring may be related to sleep apnea which is linked to more serious health 
            problems such as high blood pressure and type II diabetes.'
            path='our-services/snoring-treatment'
        >
            <div className='services'>
                <div className='services__header-snoring-treatment'>
                    <HeaderInfo
                        title='Snoring Treatment'
                        link='Snoring Treatment'
                        services
                    />
                </div>
                <div className='row'>
                    <div className='col-2-of-3'>
                        <h2 className='services__heading'>Better Sleep</h2>
                        <p className='services__paragraph'>
                            Snoring happens when turbulent air flows through the
                            upper airway causing the tissues to vibrate. This is
                            a common problem that interferes with a good nights
                            sleep and is disruptive to sleeping partners.
                            Sometimes snoring may be related to sleep apnea
                            which is linked to more serious health problems such
                            as high blood pressure and type II diabetes.
                        </p>
                        <p className='services__paragraph'>
                            A custom fitted sleep appliance may be an effective
                            alternative to CPAP or surgery. This device will
                            gently reposition the jaw and tongue forward making
                            the airway larger and improving airflow. This will
                            decrease the chance of tissue collapsing and
                            narrowing the airway when you breathe in. The result
                            will be a better night of sleep.
                        </p>
                        <div className='row'>
                            <ContactBox />
                        </div>
                    </div>
                    <div className='col-1-of-3'>
                        <ContactForm
                            name={name}
                            email={email}
                            message={message}
                            onChange={onChange}
                            onSubmit={onSubmit}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default SnoringTreatmentPage;
