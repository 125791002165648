import { Link } from 'react-router-dom';

import LogoJpg from '../assets/logo/logo.jpg';
import LogoPng from '../assets/logo/logo.png';

function Logo({ phone }) {
    const logo = phone ? (
        <img
            src={LogoJpg}
            alt='Millcroft Dental Care Logo'
            className='logo__phone'
        />
    ) : (
        <img src={LogoPng} alt='Millcroft Dental Care Logo' className='logo' />
    );

    return <Link to='/'>{logo}</Link>;
}

export default Logo;
