import { useEffect } from 'react';
import ReactCompareImage from 'react-compare-image';
import Layout from '../components/Layout';
import HeaderInfo from '../components/HeaderInfo';

import Before1 from '../assets/showcase/before1.jpg';
import Before2 from '../assets/showcase/before2.jpg';
import Before3 from '../assets/showcase/before3.jpg';
import Before4 from '../assets/showcase/before4.JPG';
import Before5 from '../assets/showcase/before5.jpg';
import Before6 from '../assets/showcase/before6.jpg';
import Before7 from '../assets/showcase/before7.jpg';
import Before8 from '../assets/showcase/before8.jpg';
import Before9 from '../assets/showcase/before9.jpg';
import After1 from '../assets/showcase/after1.jpg';
import After2 from '../assets/showcase/after2.jpg';
import After3 from '../assets/showcase/after3.jpg';
import After4 from '../assets/showcase/after4.JPG';
import After5 from '../assets/showcase/after5.jpg';
import After6 from '../assets/showcase/after6.jpg';
import After7 from '../assets/showcase/after7.jpg';
import After8 from '../assets/showcase/after8.jpg';
import After9 from '../assets/showcase/after9.jpg';

function GalleryPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            title='Millcroft Dental Care | Gallery'
            content="The gallery shows some of the procedures that were performed here are Millcroft Dental Care. We've managed to transform teeth that were in a very bad state into beautiful smiles. No matter what sort of state your teeth may be in, at Millcroft Dental Care you will always leave with your teeth in a much healthier state."
            path='gallery'
        >
            <div className='gallery__header'>
                <HeaderInfo title='Gallery' link='Gallery' />
            </div>
            <div className='gallery'>
                <div className='row'>
                    <h2 className='gallery__heading'>
                        Before and After cases (By Dr. M. Fattore)
                    </h2>
                </div>
                <div className='row'>
                    <div className='col-1-of-3'>
                        <div className='gallery__container'>
                            <h3 className='gallery__headingSecondary'>
                                Smile Enhancement
                            </h3>
                        </div>
                        <div className='gallery__container'>
                            <ReactCompareImage
                                leftImage={Before3}
                                rightImage={After3}
                            />
                        </div>
                    </div>
                    <div className='col-1-of-3'>
                        <div className='gallery__container'>
                            <h3 className='gallery__headingSecondary'>
                                Smile Enhancement
                            </h3>
                        </div>
                        <div className='gallery__container'>
                            <ReactCompareImage
                                leftImage={Before4}
                                rightImage={After4}
                            />
                        </div>
                    </div>
                    <div className='col-1-of-3'>
                        <div className='gallery__container'>
                            <h3 className='gallery__headingSecondary'>
                                Implants
                            </h3>
                        </div>
                        <div className='gallery__container'>
                            <ReactCompareImage
                                leftImage={Before9}
                                rightImage={After9}
                            />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-1-of-3'>
                        <div className='gallery__container'>
                            <h3 className='gallery__headingSecondary'>
                                Discolored Tooth
                            </h3>
                        </div>
                        <div className='gallery__container'>
                            <ReactCompareImage
                                leftImage={Before1}
                                rightImage={After1}
                            />
                        </div>
                    </div>
                    <div className='col-1-of-3'>
                        <div className='gallery__container'>
                            <h3 className='gallery__headingSecondary'>
                                Discolored Teeth
                            </h3>
                        </div>
                        <div className='gallery__container'>
                            <ReactCompareImage
                                leftImage={Before2}
                                rightImage={After2}
                            />
                        </div>
                    </div>
                    <div className='col-1-of-3'>
                        <div className='gallery__container'>
                            <h3 className='gallery__headingSecondary'>
                                Veneers
                            </h3>
                        </div>
                        <div className='gallery__container'>
                            <ReactCompareImage
                                leftImage={Before5}
                                rightImage={After5}
                            />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-1-of-3'>
                        <div className='gallery__container'>
                            <h3 className='gallery__headingSecondary'>
                                Oral Rehabilitation
                            </h3>
                        </div>
                        <div className='gallery__container'>
                            <ReactCompareImage
                                leftImage={Before6}
                                rightImage={After6}
                            />
                        </div>
                    </div>
                    <div className='col-1-of-3'>
                        <div className='gallery__container'>
                            <h3 className='gallery__headingSecondary'>
                                Oral Rehabilitation
                            </h3>
                        </div>
                        <div className='gallery__container'>
                            <ReactCompareImage
                                leftImage={Before7}
                                rightImage={After7}
                            />
                        </div>
                    </div>
                    <div className='col-1-of-3'>
                        <div className='gallery__container'>
                            <h3 className='gallery__headingSecondary'>
                                Oral Rehabilitation
                            </h3>
                        </div>
                        <div className='gallery__container'>
                            <ReactCompareImage
                                leftImage={Before8}
                                rightImage={After8}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default GalleryPage;
