import { useEffect } from 'react';
import useContactForm from '../../hooks/use-contact-form';
import Layout from '../../components/Layout';
import HeaderInfo from '../../components/HeaderInfo';
import ContactForm from '../../components/ContactForm';
import ContactBox from '../../components/ContactBox';

function ChildrensDentistryPage() {
    const { name, email, message, loading, onChange, onSubmit } =
        useContactForm();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            title="Millcroft Dental Care | Our Services - Children's Dentistry"
            content='At Millcroft Dental Care we know how important it is to get your kids off to the right start. A life-long healthy smile starts with good oral habits from a young age. We also know how intimidating and unsettling new dental experiences can be for you and your young ones! Rest assured, our friendly and comforting team aim to treat your children in a way that builds trust and instills confidence that will carry them for years to come.'
            path='our-services/childrens-dentistry'
        >
            <div className='services'>
                <div className='services__header-childrens-dentistry'>
                    <HeaderInfo
                        title="Children's Dentistry"
                        link="Children's Dentistry"
                        services
                    />
                </div>
                <div className='row'>
                    <div className='col-2-of-3'>
                        <h2 className='services__heading'>
                            A Safe Environment for your Children
                        </h2>
                        <p className='services__paragraph'>
                            At Millcroft Dental Care we know how important it is
                            to get your kids off to the right start. A life-long
                            healthy smile starts with good oral habits from a
                            young age. We also know how intimidating and
                            unsettling new dental experiences can be for you and
                            your young ones! Rest assured, our friendly and
                            comforting team aim to treat your children in a way
                            that builds trust and instills confidence that will
                            carry them for years to come.
                        </p>
                        <p className='services__paragraph'>
                            At Millcroft Dental Care our ultimate goal is to
                            provide your child with exceptional early childhood
                            dental care, in a friendly, fun, gentle and safe
                            environment. Dr. Fattore and Dr. Virk along with the
                            team hygienists have experience working with
                            children of all ages and make each visit a
                            comfortable and relaxed one for you and your
                            youngster.
                        </p>
                        <div className='row'>
                            <ContactBox />
                        </div>
                    </div>
                    <div className='col-1-of-3'>
                        <ContactForm
                            name={name}
                            email={email}
                            message={message}
                            loading={loading}
                            onChange={onChange}
                            onSubmit={onSubmit}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default ChildrensDentistryPage;
