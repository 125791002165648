import { Link } from 'react-router-dom';
import Icon1 from '../assets/icons/icon1.png';
import Icon2 from '../assets/icons/icon2.png';
import Icon3 from '../assets/icons/icon3.png';
import Icon4 from '../assets/icons/icon4.png';

function HomeAboutSection() {
    return (
        <section className='home__about'>
            <div className='home__about__box home__about__box--light'>
                <div className='home__about__icon'>
                    <img
                        className='home__about__icon__image'
                        src={Icon1}
                        alt=''
                    />
                </div>
                <p className='home__about__title'>Quality Care</p>
                <p className='home__about__description'>
                    Meet our team of doctors and staff ensuring patients are
                    always treated like family!
                </p>
                <Link
                    className='home__about__button home__about__button--dark'
                    to='/gallery'
                >
                    +
                </Link>
            </div>
            <div className='home__about__box home__about__box--dark'>
                <div className='home__about__icon'>
                    <img
                        className='home__about__icon__image'
                        src={Icon2}
                        alt=''
                    />
                </div>
                <p className='home__about__title'>Professional Experience</p>
                <p className='home__about__description'>
                    Learn more about the variety of dental services we offer
                    with years of experience.
                </p>
                <Link
                    className='home__about__button home__about__button--light'
                    to='/our-services'
                >
                    +
                </Link>
            </div>
            <div className='home__about__box home__about__box--light'>
                <div className='home__about__icon'>
                    <img
                        className='home__about__icon__image'
                        src={Icon3}
                        alt=''
                    />
                </div>
                <p className='home__about__title'>Modern Office</p>
                <p className='home__about__description'>
                    Take a tour inside our office equipped with the latest
                    technology in dentistry.
                </p>
                <Link
                    className='home__about__button home__about__button--dark'
                    to='/our-office'
                >
                    +
                </Link>
            </div>
            <div className='home__about__box home__about__box--dark'>
                <div className='home__about__icon'>
                    <img
                        className='home__about__icon__image'
                        src={Icon4}
                        alt=''
                    />
                </div>
                <p className='home__about__title'>Make a New Appointment</p>
                <p className='home__about__description'>
                    Meet our team of doctors and staff ensuring patients are
                    always treated like family!
                </p>
                <Link
                    className='home__about__button home__about__button--dark'
                    to='/contact-us'
                >
                    +
                </Link>
            </div>
        </section>
    );
}

export default HomeAboutSection;
