import { useEffect } from 'react';
import useContactForm from '../../hooks/use-contact-form';
import Layout from '../../components/Layout';
import HeaderInfo from '../../components/HeaderInfo';
import ContactForm from '../../components/ContactForm';
import ContactBox from '../../components/ContactBox';

function GeneralDentistryPage() {
    const { name, email, message, loading, onChange, onSubmit } =
        useContactForm();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            title='Millcroft Dental Care | Our Services - General Dentistry'
            content='Regular checkups and cleanings are vital to maintaining healthy gums and teeth – and for keeping up your overall health. Millcroft Dental Care makes office visits simple. We offer flexible appointment times, a convenient location, and a friendly, experienced staff, so you’ll be in and out in no time.'
            path='our-services/general-dentistry'
        >
            <div className='services'>
                <div className='services__header-general-dentistry'>
                    <HeaderInfo
                        title='General Dentistry'
                        link='General Dentistry'
                        services
                    />
                </div>
                <div className='row'>
                    <div className='col-2-of-3'>
                        <h2 className='services__heading'>
                            Get Necessary Regular Checkups
                        </h2>
                        <h3 className='services__headingSecondary'>
                            Keep your teeth and gums healthy
                        </h3>
                        <p className='services__paragraph'>
                            Regular checkups and cleanings are vital to
                            maintaining healthy gums and teeth – and for keeping
                            up your overall health. Millcroft Dental Care makes
                            office visits simple. We offer flexible appointment
                            times, a convenient location, and a friendly,
                            experienced staff, so you’ll be in and out in no
                            time.
                        </p>
                        <div className='row'>
                            <ContactBox />
                        </div>
                    </div>
                    <div className='col-1-of-3'>
                        <ContactForm
                            name={name}
                            email={email}
                            message={message}
                            onChange={onChange}
                            onSubmit={onSubmit}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default GeneralDentistryPage;
