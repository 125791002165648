import { NavLink } from 'react-router-dom';
import Backdrop from './Backdrop';
import Logo from './Logo';

function SideDrawer({ open, onClose }) {
    return (
        <>
            <Backdrop show={open} clicked={onClose} />
            <div
                className={[
                    'sidedrawer',
                    open ? 'sidedrawer--open' : 'sidedrawer--closed',
                ].join(' ')}
            >
                <Logo phone />
                <nav className='sidedrawer__links'>
                    <NavLink className='sidedrawer__link' to='/'>
                        Home
                    </NavLink>
                    <NavLink className='sidedrawer__link' to='/our-office'>
                        Our Office
                    </NavLink>
                    <NavLink className='sidedrawer__link' to='/our-services'>
                        Our Services
                    </NavLink>
                    <NavLink className='sidedrawer__link' to='/our-blog'>
                        Our Blog
                    </NavLink>
                    <hr className='sidedrawer__separator' />
                    <NavLink className='sidedrawer__link' to='/faq'>
                        FAQ
                    </NavLink>
                    <NavLink className='sidedrawer__link' to='/patient-form'>
                        Patient Form
                    </NavLink>
                    <NavLink className='sidedrawer__link' to='/gallery'>
                        Gallery
                    </NavLink>
                    <NavLink className='sidedrawer__link' to='/contact-us'>
                        Contact Us
                    </NavLink>
                </nav>
            </div>
        </>
    );
}

export default SideDrawer;
