import { useEffect } from 'react';
import useContactForm from '../hooks/use-contact-form';
import Layout from '../components/Layout';
import HomeContactInfo from '../components/HomeContactInfo';
import HomeContactForm from '../components/HomeContactForm';
import HeaderInfo from '../components/HeaderInfo';

function ContactPage() {
    const { name, email, message, loading, onChange, onSubmit } =
        useContactForm();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            title='Millcroft Dental Care | Contact Us'
            content='There are different ways that you can get in contact with Millcroft Dental Care to make an appoitment. You can call us, send us an email, or use our contact form. If you send us a message through email or our contact form, we will get back to you as soon as we can and set up your appoitment.'
            path='contact-us'
        >
            <div className='contact'>
                <div className='contact__header'>
                    <HeaderInfo title='Contact Us' link='Contact Us' />
                </div>
                <section className='home__contact'>
                    <h2 className='home__contact__headerprimary'>
                        Ready for your dream smile?
                    </h2>
                    <h3 className='home__contact__headersecondary'>
                        Achieve it with our professionals at Millcroft Dental
                        Care
                    </h3>
                    <div className='row'>
                        <div className='col-2-of-3'>
                            <HomeContactInfo />
                        </div>
                        <div className='col-1-of-3'>
                            <HomeContactForm
                                name={name}
                                email={email}
                                message={message}
                                loading={loading}
                                onChange={onChange}
                                onSubmit={onSubmit}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
}

export default ContactPage;
