import { useEffect } from 'react';
import useContactForm from '../../hooks/use-contact-form';
import Layout from '../../components/Layout';
import HeaderInfo from '../../components/HeaderInfo';
import ContactForm from '../../components/ContactForm';
import ContactBox from '../../components/ContactBox';

function WisdomTeethPage() {
    const { name, email, message, loading, onChange, onSubmit } =
        useContactForm();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Layout
            title='Millcroft Dental Care | Our Services - Wisdom Teeth'
            content="We examine whether your wisdom teeth are causing a problem, and if they are then we have the services required to have them safely removed. If you have wisdom teeth that are bothering you, or you aren't sure whether they should be removed, feel free to contact us and book an appointment."
            path='our-services/wisdom-teeth'
        >
            <div className='services'>
                <div className='services__header-wisdom-teeth'>
                    <HeaderInfo
                        title='Wisdom Teeth'
                        link='Wisdom Teeth'
                        services
                    />
                </div>
                <div className='row'>
                    <div className='col-2-of-3'>
                        <h2 className='services__heading'>
                            Wisdom Teeth Causing Problems?
                        </h2>
                        <h3 className='services__headingSecondary'>
                            Wisdom Teeth Discomfort
                        </h3>
                        <p className='services__paragraph'>
                            Many of us have jaws that aren't large enough to
                            accomodate wisdom teeth when they become present.
                            When there isn't enough room for the wisdom teeth to
                            be comfortably present, then your other teeth push
                            against them causing pain and other problems in your
                            mouth.
                        </p>
                        <h3 className='services__headingSecondary'>
                            Have your wisdom teeth examined
                        </h3>
                        <p className='services__paragraph'>
                            We examine whether your wisdom teeth are causing a
                            problem, and if they are then we have the services
                            required to have them safely removed. If you have
                            wisdom teeth that are bothering you, or you aren't
                            sure whether they should be removed, feel free to
                            contact us and book an appointment.
                        </p>
                        <div className='row'>
                            <ContactBox />
                        </div>
                    </div>
                    <div className='col-1-of-3'>
                        <ContactForm
                            name={name}
                            email={email}
                            message={message}
                            onChange={onChange}
                            onSubmit={onSubmit}
                            loading={loading}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default WisdomTeethPage;
