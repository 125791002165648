import { Oval } from 'react-loader-spinner';

function ContactForm({ name, email, message, loading, onChange, onSubmit }) {
    return (
        <form className='contactform' onSubmit={onSubmit}>
            <h2 className='contactform__heading'>Contact Us</h2>
            <input
                className='contactform__input'
                name='name'
                type='text'
                placeholder='Name*'
                onChange={onChange}
                value={name}
                required
            />
            <input
                className='contactform__input'
                name='email'
                type='email'
                placeholder='Email*'
                onChange={onChange}
                value={email}
                required
            />
            <textarea
                className='contactform__textarea'
                name='message'
                cols='30'
                rows='10'
                placeholder='Message'
                onChange={onChange}
                value={message}
                required
            ></textarea>
            <div className='contactform__submit'>
                {loading ? (
                    <div className='contactform__submit__loader'>
                        <Oval color='#424242' height={50} width={50} />
                    </div>
                ) : (
                    <button
                        className='contactform__submit__button'
                        htmltype='submit'
                    >
                        Send
                    </button>
                )}
            </div>
        </form>
    );
}

export default ContactForm;
