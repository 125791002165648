import OfficeImage1 from '../assets/office/OfficeImage01.jpg';
import OfficeImage2 from '../assets/office/OfficeImage02.jpg';
import OfficeImage3 from '../assets/office/OfficeImage03.jpg';
import OfficeImage4 from '../assets/office/OfficeImage04.jpg';
import OfficeImage5 from '../assets/office/OfficeImage05.jpg';
import OfficeImage6 from '../assets/office/OfficeImage06.jpg';

export const renderImage = (i, setImage, openModal) => {
    if (i === 0) {
        setImage(
            <div className='office__modaldisplay'>
                <img
                    className='office__modaldisplay__image'
                    src={OfficeImage1}
                    alt=''
                />
            </div>
        );
    } else if (i === 1) {
        setImage(
            <div className='office__modaldisplay'>
                <img
                    className='office__modaldisplay__image'
                    src={OfficeImage2}
                    alt=''
                />
            </div>
        );
    } else if (i === 2) {
        setImage(
            <div className='office__modaldisplay'>
                <img
                    className='office__modaldisplay__image'
                    src={OfficeImage3}
                    alt=''
                />
            </div>
        );
    } else if (i === 3) {
        setImage(
            <div className='office__modaldisplay__portrait'>
                <img
                    className='office__modaldisplay__portrait__image'
                    src={OfficeImage4}
                    alt=''
                />
            </div>
        );
    } else if (i === 4) {
        setImage(
            <div className='office__modaldisplay__portrait'>
                <img
                    className='office__modaldisplay__portrait__image'
                    src={OfficeImage5}
                    alt=''
                />
            </div>
        );
    } else if (i === 5) {
        setImage(
            <div className='office__modaldisplay__portrait'>
                <img
                    className='office__modaldisplay__portrait__image'
                    src={OfficeImage6}
                    alt=''
                />
            </div>
        );
    }

    openModal();
};
